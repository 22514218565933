import React, {useEffect, useContext, useState} from 'react';
import moment from 'moment';
import {Entity, UserContext, fetchAuthenticatedContent} from '@parallelpublicworks/entitysync';
import {graphql} from 'gatsby';
import {FileRelationship} from '../components/carbon-entitysync';
import {InlineNotification} from 'gatsby-theme-carbon';
import getProfessorRequests from '../util/getProfessorRequests';
import slugify from '../util/slugify';
import { InlineLoading } from 'carbon-components-react'
import ReactHtmlParser from 'react-html-parser'

function Letter({pageContext, location, setError, data}) {
  const [userState, dispatch] = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [gotRequests, setGotRequests] = useState(false)
  let duedate = data.allNodeParameters.edges[0].node.field_letter_due_date.value 
  ? data.allNodeParameters.edges[0].node.field_letter_due_date.value : ""
  let sidetxt = data.allNodeParameters.edges[0].node.field_lor_sidetext.value 
  ? data.allNodeParameters.edges[0].node.field_lor_sidetext.value : ""
  // const source = getReferringAnswers()
  //const { executeRecaptcha } = useGoogleReCaptcha();


  const generateRequestFilenames = async (requests) => {
    return requests.map(async request => {
      const userUUID = request?.relationships?.uid?.data?.id
      const endpoint = `user/user/${userUUID}`
      if (userUUID) {
        // [webform_submission:values:student_id]-[webform_submission:values:name]-[current-user:display-name]-[current-date:custom:Y-m-d]-LOR
        const userEntity = await fetchAuthenticatedContent(userState.auth, dispatch, endpoint)
        if(userEntity && userEntity.data){
          const studentUid = userEntity.data.attributes.drupal_internal__uid
          const fullName = userEntity.data.attributes.field_full_name?.replace(' ', '-')
          const name = userEntity.data.attributes.name
          const date = moment().format('YY-MM-DD')
          const professorUsername = slugify(userState.userData[userState.currentUserId].attributes.display_name)
          request.filename = `${studentUid}-${fullName ? fullName : name}-${professorUsername}-${date}-LOR` 
        }
      }
      return request
    })
  }
  /**
   * TODO
   * Check for user roles, if not 'Professor'
   * Redirect back to application
   */
  const getRequests = (userState, dispatch, setRequests) => {
    // const usr = userState.userData.find(usr => usr.type === 'user--user')
    for (let entity in userState.userData) {
      if (userState.userData[entity].type === 'user--user') {
   
          // if (!resp) navigate('/')
          getProfessorRequests(userState.auth, dispatch, userState.userData[entity].attributes.mail).then(resp2 => {
            if (resp2) {
              generateRequestFilenames(resp2).then((reqs => {
                Promise.all(reqs).then(results => {
                  setGotRequests(true)
                  setRequests(results)
                })
              }))
            }
          })
        
      } 
    }
  }

  useEffect(() => {
    if(!gotRequests){
      getRequests(userState, dispatch, setRequests) 
    }
  }, [])

  // if (!requests.length && !gotRequests) {
  //   getRequests(userState, dispatch, setRequests)
  // }

  const taskLayout = (
    <div className="application-wrapper application">
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            {duedate && (
              <div id="deadline" className="page-task">
                <div className="padd first">
                  <br/>
                  <p 
                  style={{textAlign: 'left'}}>
                  {ReactHtmlParser(sidetxt)}</p>
                  <br/>
                  <InlineNotification kind="info">
                  <p className="bx--col-lg-12">
                    {ReactHtmlParser(duedate)}
                  </p>
                  </InlineNotification>
                </div>
              </div>
            )}
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
          <div>
            <div id="application-title"  className="padd">
              <h2>Letter of Recommendation Upload</h2>
            </div>
            <div id="upload">
              {
                requests && requests.length ? requests.map(request => {
                  return (
                    <div className="lor-request-item">
                      <Entity key={request.id} source={request} autosave type="node--lor_request" componentId={request.id}>
                        <h5>{request.attributes.title}</h5>
                        <p className="bx--label">{request.attributes.field_personal_message.value}</p>
                        <FileRelationship light field="field_letter" filename={request.filename} autosaving />
                      </Entity>
                    </div>
                  )
                }) : gotRequests ? 
                <div className="lor-request-item"><h5>No requests found for this user</h5></div> : 
                <div className="lor-request-item"><InlineLoading className="padd" description="Loading..." status="active" /></div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>    
  )

  return taskLayout
}

export default function LetterPage({pageContext, location, data}) {
  pageContext = {...pageContext, frontmatter: {title: "Letter of Recommendation Upload", tabs: [], slug: '/letter'}};
  return (

      <Letter pageContext={pageContext} location={location} data={data} />

  )
}

export const query = graphql`
{
  allNodeParameters {
    edges {
      node {
        field_lor_sidetext {
          value
        }
        field_letter_due_date {
          value
        }
      }
    }
  }
}
`
